<template>
	<div class="address-list-container" v-if="IsShow">
		<div class="mark" @click="close()"></div>
		<div class="address-list" @click.stop>
			<div class="address-list-outbox">
				<div class="list-header">
					<div class="title-box">
						<div class="title">{{$t('地址管理')}}</div>
						<div class="add" @click.stop="add()">
							<div>+</div>
							<div>{{$t('添加地址')}}</div>
						</div>
					</div>
					<div class="close" @click="close()">
					</div>
				</div>
				<div class="list-body">
					<div class="li-item" v-for="(item,index) in list" :key="index" @click="changeAddress(item,index)">
						<div class="user-info" style="font-size: 18px;">
							<div class="col">
								<div>{{$t('收件人')}}：</div>
								<div>{{item.linkman}}</div>
							</div>
							<div class="col">
								<div>{{$t('联系电话')}}：</div>
								<div>{{item.mobile}}</div>
							</div>
						</div>
						<div class="address-info-box">
							<div class="address-info" style="font-size: 18px;">
								<div class="col">
									<div style="color: #999;">{{$t('国家/地区')}}：</div>
									<div>{{item.area}}</div>
								</div>
								<div class="col" v-if="item.province">
									<div style="color: #999;">{{$t('省/州')}}：</div>
									<div>{{item.province}}</div>
								</div>
								<div class="col">
									<div style="color: #999;">{{$t('城市')}}：</div>
									<div>{{item.city}}</div>
								</div>
								<div class="col" v-if="item.zip_code">
									<div style="color: #999;">{{$t('邮编')}}：</div>
									<div>{{item.zip_code}}</div>
								</div>
								<div class="col grid-column-max-span">
									<div style="color: #999;">{{$t('详细地址')}}：</div>
									<div>{{item.address}}</div>
								</div>
							</div>
							<div class="address-action">
								<div class="edit-btn" @click.stop="edit(item)">{{$t('修改地址')}}</div>
								<Button class="del-btn" @click.stop="del(item.id)">{{$t('删除')}}</Button>
							</div>
						</div>
					</div>
					<div v-if="list.length == 0" class="_pc-font-36 _b _pc-mar-y-45 _text-center">~{{$t('暂无数据')}}~</div>
				</div>
			</div>
		</div>
		<!-- 修改、添加地址 -->
		<edit-address ref="editAddress" :IsShow="show_edit_address"  @close="editAdressClose"></edit-address>
	</div>
</template>

<script>
	import editAddress from './edit_address.vue'
	import { getReceiveAddressList, delAddress } from '@/api/jiyun.js'
	export default {
		components: {
			editAddress
		},
		props: {
			"IsShow":{
				type: Boolean,
				default:false
			}
		},
		data() {
			return {
				is_hid_list:true,
				show_address_list:true,
				show_edit_address:false,
				address:{},
				list:[],
			}
		},
		watch:{
			show_edit_address:{
				handler(){
					if(!this.show_edit_address){
						this.$refs.editAddress.id = 0;
						this.$refs.editAddress.linkman = '';
						this.$refs.editAddress.mobile = '';
						this.$refs.editAddress.area_id = 0;
						this.$refs.editAddress.area = '';
						this.$refs.editAddress.province = '';
						this.$refs.editAddress.city = '';
						this.$refs.editAddress.address = '';
						this.$refs.editAddress.zip_code = '';
					}
				},
			},
		},
		mounted() {
			this.getList();
		},
		methods: {
			stop(){
				
			},
			close() {
				this.$emit('close',false)
			},
			// 关闭编辑地址
			editAddressClose(val) {
				this.open_edit_adress = val;
			},
			del(id) {
				let param = {
					address_id:id
				}
				let that = this;
				this.$Modal.confirm({
					title: this.$t('删除'),
					content: this.$t('删除该地址'),
					okText: this.$t('confirm'),
					cancelText: this.$t('cancel'),
					onOk(){
						delAddress(param).then(res=>{
							this.$Message.success('删除成功');
							that.getList();
						}).cath(res=>{
							this.$Message.error(res.msg);
						})
					}
				})
			},
			// 编辑地址
			edit(data) {
				this.$refs.editAddress.id = data.id;
				this.$refs.editAddress.linkman = data.linkman;
				this.$refs.editAddress.mobile = data.mobile;
				this.$refs.editAddress.area_id = data.area_id;
				this.$refs.editAddress.area = data.area;
				this.$refs.editAddress.province = data.province;
				this.$refs.editAddress.city = data.city;
				this.$refs.editAddress.address = data.address;
				this.$refs.editAddress.zip_code = data.zip_code;
				this.show_edit_address = true;
				this.$nextTick(()=>{
					this.$refs.editAddress.getProvince();
				})
			},
			editAdressClose(val,type) {
				this.show_edit_address = val;
				if(type){
					this.getList();
				}
			},
			// 添加地址
			add() {
				this.show_edit_address = true;
			},

			getList(){
				getReceiveAddressList().then(res=>{
					this.list = res.data;
				})
			},
			// 获取地址信息
			changeAddress(item,index) {
				this.close();
				this.$emit('change',item);
			}
 		}
	}
</script>

<style scoped>
	.address-list-container {
		position: fixed;
		background: rgba(0, 0, 0, 0.3);
		z-index: 666;
		inset: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.address-list-container .mark {
		position: absolute;
		inset: 0;
	}
	.address-list {
		position: relative;
		z-index: 2;
		width: 80%;
		border-radius: 0.625rem;
		background-color: #ffffff;
		border: 1px solid #fff;
	}
	.address-list .address-list-outbox {
		width: 100%;
		color: #333333;
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.address-list .address-list-outbox .list-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0rem 0.9375rem;
		height: 6.25rem;
		border-bottom: 1px solid #d8d8d8;
	}
	.address-list .address-list-outbox .list-header .title-box {
		display: flex;
		align-items: center;
		column-gap: 1.125rem;
	}
	.address-list .address-list-outbox .list-header .title-box .title {
		font-weight: bold;
		font-size: 1.75rem;
	}
	.address-list .address-list-outbox .list-header .title-box .add {
		background-color: rgba(66, 132, 220, 0.15);
		border-radius: 0.375rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0.368rem 1rem;
		font-size: 0.9375rem;
		color: rgba(66, 132, 220, 1);
		column-gap: 0.25rem;
		cursor: pointer;
	}
	.address-list .address-list-outbox .list-header .close {
		width: 2rem;
		aspect-ratio: 1/1;
		border-radius: 50%;
		/* background-color: #e6e6e6; */
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		transform: rotate(45deg);
		cursor: pointer;
	}
	.address-list .address-list-outbox .list-header .close::before {
		content: '';
		position: absolute;
		width: 100%;
		height: 2px;
		background-color: #999;
	}
	.address-list .address-list-outbox .list-header .close::after {
		content: '';
		position: absolute;
		width: 2px;
		height: 100%;
		background-color: #999;
	}
	.address-list .address-list-outbox .list-body {
		width: 100%;
		padding: 1.5625rem 0.9375rem;
		height: 40.5rem;
		overflow: hidden;
		overflow-y: scroll;
	}
	.address-list .address-list-outbox .list-body .li-item {
		width: 100%;
		border: 1px solid #D9D9D9;
		margin-bottom: 0.625rem;
		cursor: pointer;
	}
	.address-list .address-list-outbox .list-body .li-item .user-info {
		width: 100%;
		font-size: 0.9375rem;
		padding: 0.9375rem 0.9375rem;
		display: grid;
		grid-template-columns: auto 1fr;
		background-color: #f5f5f5;
		grid-column-gap: 5rem;
		border-bottom: 1px solid #D9D9D9;
	}
	.address-list .address-list-outbox .list-body .li-item .user-info .col {
		display: flex;
		align-items: center;
	}
	.address-list .address-list-outbox .list-body .li-item .address-info-box {
		display: grid;
		grid-template-columns: 1fr auto;
		padding: 0rem 0.9375rem;
	}
	.address-list .address-list-outbox .list-body .li-item .address-info-box .address-info {
		width: 100%;
		padding: 0.9375rem 0rem;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-column-gap: 8.75rem;
		grid-row-gap: 0.375rem;
	}
	.address-list .address-list-outbox .list-body .li-item .address-info-box .address-info .col{
		display: flex;
		align-items: center;
	}
	.address-list .address-list-outbox .list-body .li-item .address-info-box .address-action {
		display: flex;
		align-items: center;
		height: 100%;
		column-gap: 1.125rem;
	}
	.address-list .address-list-outbox .list-body .li-item .address-info-box .address-action .edit-btn {
		color: #fff;
		border-radius: 0.375rem;
		background-color: #4284DC;
		padding: 0.375rem 0.75rem;
		cursor: pointer;
	}
	.address-list .address-list-outbox .list-body .li-item .address-info-box .address-action .del-btn {
		color: #C81920;
		border-radius: 0.375rem;
		border: 1px solid;
		border-color: #C81920;
		padding: 0.375rem 0.75rem;
		cursor: pointer;
	}
	.grid-column-max-span{
		grid-column: 1 / -1;
	}
	.edit-address-box {
		position: fixed;
		inset: 0;
		z-index: 777;
	}
</style>