<template>
	<div class="container">
		<div class="list-container">
			<div class="header-container">
				<div class="title">
					<div>{{$t('申请打包')}}</div>
					<div>(<span>{{2}}</span>/<span>{{2}}</span>)</div>
				</div>
				<div class="action">
					<div class="sub-btn _pc-display-none">
						<div class="_pc-display-none">{{$t('申请打包')}}</div>
					</div>
					<div @click="back" class="return-btn display-none">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="17px">
							<path fill-rule="evenodd" fill="rgb(66, 132, 220)" d="M9.522,4.267 L3.139,4.267 L5.276,2.137 C5.571,1.842 5.571,1.365 5.276,1.072 C4.980,0.777 4.502,0.777 4.208,1.072 L0.857,4.411 C0.695,4.573 0.627,4.789 0.641,4.999 C0.625,5.210 0.695,5.425 0.857,5.587 L4.206,8.926 C4.354,9.074 4.547,9.148 4.740,9.148 C4.933,9.148 5.126,9.074 5.274,8.926 C5.570,8.631 5.570,8.155 5.274,7.862 L3.180,5.774 L9.427,5.774 C12.065,5.774 14.289,7.846 14.348,10.476 C14.410,13.179 12.222,15.398 9.524,15.398 L8.558,15.398 C8.547,15.398 8.534,15.396 8.523,15.396 L2.041,15.396 C2.028,15.396 2.017,15.398 2.006,15.398 C1.645,15.414 1.353,15.680 1.296,16.027 L1.296,16.275 C1.353,16.621 1.645,16.887 2.003,16.905 L9.411,16.905 C12.884,16.905 15.794,14.164 15.858,10.703 C15.923,7.166 13.055,4.267 9.522,4.267 Z" />
						</svg>
						<span>{{$t('返回')}}</span>
					</div>
				</div>
				<div class="mb-return _pc-display-none" @click="back">
					<svg style="transform: scale(0.85);" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16px" height="17px">
						<path fill-rule="evenodd" fill="rgb(255, 255, 255)" d="M9.522,4.267 L3.139,4.267 L5.276,2.137 C5.571,1.842 5.571,1.365 5.276,1.072 C4.980,0.777 4.502,0.777 4.208,1.072 L0.857,4.411 C0.695,4.573 0.627,4.789 0.641,4.999 C0.625,5.210 0.695,5.425 0.857,5.587 L4.206,8.926 C4.354,9.074 4.547,9.148 4.740,9.148 C4.933,9.148 5.126,9.074 5.274,8.926 C5.570,8.631 5.570,8.155 5.274,7.862 L3.180,5.774 L9.427,5.774 C12.065,5.774 14.289,7.846 14.348,10.476 C14.410,13.179 12.222,15.398 9.524,15.398 L8.558,15.398 C8.547,15.398 8.534,15.396 8.523,15.396 L2.041,15.396 C2.028,15.396 2.017,15.398 2.006,15.398 C1.645,15.414 1.353,15.680 1.296,16.027 L1.296,16.275 C1.353,16.621 1.645,16.887 2.003,16.905 L9.411,16.905 C12.884,16.905 15.794,14.164 15.858,10.703 C15.923,7.166 13.055,4.267 9.522,4.267 Z" />
					</svg>
					<span>{{$t('返回')}}</span>
				</div>
			</div>
			<div class="main-container">
				<div class="pack-list-box">
					<div class="title">
						<span>{{$t('已选择包裹')}}</span>
						<span>({{choose_list.length}})</span>
					</div>
					<div class="content">
						<div class="item" v-for="(item,index) in choose_list" :key="index">
							<img src="~@/assets/image/hangda/baoguo.png" alt="">
							<div>{{$t('快递单号')}}：</div>
							<div>{{item.expresssn}}</div>
						</div>
					</div>
				</div>
				<div class="address-box">
					<div class="title">
						<div>{{$t('海外收货地址')}}</div>
						<div class="_pc-display-none">
							<div style="display: flex;align-items: center;">
								<div class="qiehuan-box" style="width: 0.9375rem;margin-right: 0.375rem;">
									<img style="width: 100%;" src="~@/assets/image/hangda/qiehuan6.png" alt="" />
								</div>
								<div style="font-size: 0.9375rem;color: #4284DC;">{{$t('更换')}}</div>
							</div>
						</div>
					</div>
					<div class="content">
						<div class="address">
							<div class="left" v-if="addressInfo">
								<div class="__1">
									<div style="font-size: 1.125rem;">
										<strong>{{addressInfo.linkman}}</strong>
									</div>
									<div class="phone" style="color: #4284DC;">{{$t('联系电话')}}：{{addressInfo.mobile||''}}</div>
								</div>
								<div class="__2">
									<!-- <div>{{addressInfo.area + addressInfo.city + "("+addressInfo.city_alias+")" + addressInfo.address}}</div> -->
									<div>{{addressInfo.area ||''}}{{addressInfo.province||''}}{{addressInfo.city||''}}{{addressInfo.address||''}}</div>
								</div>
							</div>
							<div class="left" style="color: #999;" v-else>{{$t('请选择收货地址...')}}...</div>
							<div class="right display-none" style="cursor: pointer;" @click="show_address_list = true">
								<div class="qiehuan-box">
									<img style="width: 100%;" src="~@/assets/image/hangda/qiehuan6.png" alt="" />
								</div>
								<div v-if="addressInfo">{{$t('更换')}}</div>
								<div v-else>{{$t('选择')}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="router-select-box">
					<div class="title">
						<div>{{$t('路线')}}</div>
					</div>
					<div class="content">
						<div class="select-box">
							<div class="select-input" @click="selectInput">
								<div>
									<span v-if="!routeValue.express_name" style="color: #999;">{{$t('请选择路线')}}...</span>
									<span v-else>{{routeValue.express_name}}</span>
								</div>
								<div class="img-box" :style="show_select?'transform: rotateZ(180deg);':''">
									<img style="width: 100%;" src="~@/assets/image/hangda/xiala6.png" alt="下拉" />
								</div>
							</div>
							<div class="select-list" v-if="show_select">
								<div class="select-item" v-for="(item,index) in route" :key="index" @click="selectOption(item)">
									<span v-if="lang == 'zh_cn'">{{item.express_name}}</span>
									<span v-else-if="lang == 'en_us'">{{item.express_name_us}}</span>
									<span v-else>{{item.express_name_ru}}</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="remark-box">
					<div class="title">
						<div>{{$t('备注')}}</div>
					</div>
					<div class="content">
						<textarea v-model="remark" :placeholder="$t('请输入备注')"></textarea>
					</div>

				</div>
				<div class="save-box">
					<div class="save-btn" @click="save()">{{$t('申请打包')}}</div>
				</div>
			</div>
		</div>
		<address-list :IsShow="show_address_list" @close="addressClose()" @change="changeAddress"></address-list>
	</div>
</template>

<script>
	import {
		getAreaLine,
		createOrder
	} from '@/api/jiyun.js'
	import addressList from './address_list'
	export default {
		components: {
			addressList
		},
		data() {
			return {
				choose_list: [], //包裹列表
				areas: [], //国家
				areaIndex: null, //国家索引
				areaExpress: [], //路线
				expressIndex: null, //路线索引
				expressID: null, //路线ID
				packageID: null, //包裹信息ID
				expresssns: null, //快递单号
				remark: null, //包裹备注
				goods_money: null, //货品价值
				storehouse_name: null, //仓库

				checkPush: true, //防止重复点击
				need_sender: 0, //是否需要填写寄件人
				sender: {
					sender_lastname: '',
					sender_name: '',
					sender_mobile: '',
					sender_address: '',
					email: '',
				}, //寄件人信息

				recInfo: {}, //收件人信息

				areasInfo: { //国家信息
					ID: null, //国家ID
					name: null, //国家名字
				},
				show_select: false,
				show_address_list: false,
				route: [], //路线
				routeValue: '', //路线
				addressInfo: '', //收货地址
				lang: 'ru',
			}
		},
		created() {

		},
		mounted() {
			let package_ids = [];
			this.lang = localStorage.getItem('think-lang') || 'zh_cn';
			if (JSON.parse(this.$route.query.listSelect)) {
				this.choose_list = JSON.parse(this.$route.query.listSelect);
				this.choose_list.forEach(val => {
					package_ids.push(val.id)
				});
				this.packageID = package_ids.join();
			}
		},
		methods: {
			// 返回
			back() {
				this.$router.back()
			},
			selectInput() {
				this.show_select = !this.show_select;
			},
			// 选择路线
			selectOption(val) {
				this.routeValue = val;
				this.show_select = !this.show_select;
				this.expressID = this.routeValue.id;
			},
			// 关闭地址列表
			addressClose() {
				this.show_address_list = false
			},
			// 选择收货地址
			changeAddress(e) {
				this.addressInfo = e;
				let id = 0;
				id = this.addressInfo.area_id;
				getAreaLine(id).then(res => { //获取集运路线
					this.route = res.data;
				})
			},
			// 申请打包
			save() {
				let params = {
					package_ids: this.packageID,
					area_express_id: this.expressID,
					linkman: this.addressInfo.linkman,
					mobile: this.addressInfo.mobile,
					province: this.addressInfo.province,
					city: this.addressInfo.city,
					clearance_num: this.addressInfo.clearance_num,
					zip_code: this.addressInfo.zip_code,
					address: this.addressInfo.address,
					area_id: this.addressInfo.area_id,
					area: this.addressInfo.area,
					sender: {
						sender_name: this.sender.sender_name,
						sender_mobile: this.sender.sender_mobile,
						sender_address: this.sender.sender_address
					},
					remark: this.remark
				};
				if (!params.package_ids) {
					return this.$Message.error(this.$t('请选择包裹'))
				}
				if (!params.area_id) {
					return this.$Message.error(this.$t('请选择收货地址'))
				}
				if (!params.area_express_id) {
					return this.$Message.error(this.$t('请选择路线'))
				}
				createOrder(params).then(res => { //获取集运路线
					this.$Message.success(res.msg)
					this.$Spin.show({
						render: (h) => {
							return h('div', [
								h('Icon', {
									'class': 'demo-spin-icon-load',
									props: {
										type: 'ios-loading',
										size: 18
									}
								}),
								h('div', this.$t('操作成功'))
							])
						}
					});
					setTimeout(() => {
						this.$Spin.hide();
						this.$router.replace({
							path: '/user'
						})
					}, 1000);

				})
			}
		}
	}
</script>

<style scoped>
	.container {
		color: #333333;
	}

	/* pc */
	@media screen and (min-width: 768px) {
		.container {
			width: 100%;
			padding: 2.5rem 17.5rem;
			background-color: #f5f5f5;
		}

		.list-container {
			width: 100%;
			background-color: #ffffff;
			border-radius: 0.625rem;
		}

		.header-container {
			padding: 2.1875rem 1.5625rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #E9E9E9;
		}

		.header-container .title {
			display: flex;
			align-items: center;
			column-gap: 0.375rem;
			font-size: 2rem;
			font-weight: bold;
			flex: 1;
		}

		.header-container .action {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 0.9375rem;
		}

		.header-container .action .sub-btn {
			font-size: 1.125rem;
			color: #fff;
			background: #4284DC;
			padding: 0.875rem 2rem;
			border-radius: 1rem;
			cursor: pointer;
		}

		.header-container .action .return-btn {
			font-size: 1.125rem;
			color: #4284DC;
			border: 1px solid #4284DC;
			padding: 0.875rem 2rem;
			border-radius: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.header-container .action .return-btn span {
			margin-left: 0.3125rem;
		}

		.main-container {
			width: 100%;
			padding: 2.1875rem 1.5625rem;
		}

		.main-container .pack-list-box {
			width: 100%;
		}

		.main-container .pack-list-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
		}

		.main-container .pack-list-box .content {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-column-gap: 4.375rem;
			grid-row-gap: 0.625rem;
			font-size: 0.9075rem;
		}

		.main-container .pack-list-box .content .item {
			display: flex;
			align-items: center;
		}

		.main-container .pack-list-box .content .item img {
			margin-right: 0.625rem;
			width: 1rem;
			aspect-ratio: 1/1;
		}

		.main-container .address-box {
			width: 100%;
		}

		.main-container .address-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			margin-top: 1.25rem;
		}

		.main-container .address-box .content {
			display: grid;
			grid-template-columns: 60% 1fr;
		}

		.main-container .address-box .content .address {
			width: 100%;
			background-color: #f5f5f5;
			border-radius: 0.5rem;
			border: 1px solid #d6d6d6;
			padding: 1.5rem 1.5625rem;
			display: grid;
			grid-template-columns: 1fr auto;
		}

		.main-container .address-box .content .address .left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			row-gap: 0.375rem;
		}

		.main-container .address-box .content .address .left .__1 {
			display: flex;
			align-items: flex-end;
		}

		.main-container .address-box .content .address .left .__1 .phone {
			margin-left: 0.625rem;
		}

		.main-container .address-box .content .address .right {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #4284DC;
			row-gap: 0.375rem;
			font-size: 0.9375rem;
		}

		.main-container .address-box .content .address .right .qiehuan-box {
			width: 1.25rem;
			aspect-ratio: 1/1;
		}

		.main-container .address-box {
			width: 100%;
		}

		.main-container .address-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			margin-top: 1.25rem;
		}

		.main-container .router-select-box {
			width: 100%;
		}

		.main-container .router-select-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			margin-top: 1.25rem;
		}

		.main-container .router-select-box .content {
			width: 100%;
			display: grid;
			grid-template-columns: 40% 1fr;
		}

		.main-container .router-select-box .content .select-box {
			width: 100%;
			background: #f5f5f5;
			border: 1px solid #d6d6d6;
			border-radius: 0.5rem;
			position: relative;
		}

		.main-container .router-select-box .content .select-box .select-input {
			cursor: pointer;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1.125rem 0.9375rem;
		}

		.main-container .router-select-box .content .select-box .img-box {
			width: 1rem;
			aspect-ratio: 1/1;
		}

		.main-container .router-select-box .content .select-box .select-list {
			position: absolute;
			top: 110%;
			background: #f5f5f5;
			border: 1px solid #d6d6d6;
			min-height: 0.625rem;
			max-height: 18.75rem;
			width: 100%;
			z-index: 6;
			left: 0;
			border-radius: 0.375rem;
			overflow: hidden;
			overflow-y: scroll;
		}

		.main-container .router-select-box .content .select-box .select-list .select-item {
			padding: 0.9375rem;
			font-size: 0.9375rem;
			border-bottom: 1px solid #E9E9E9;
		}

		.main-container .router-select-box .content .select-box .select-list .select-item:last-child {
			border-bottom: none;
		}

		.main-container .router-select-box .content .select-box .select-list .select-item:hover {
			background: rgba(66, 132, 220, 0.1);
			color: #4284DC;
		}

		.main-container .remark-box {
			width: 100%;
		}

		.main-container .remark-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			margin-top: 1.25rem;
		}

		.main-container .remark-box .content {
			width: 100%;
			display: grid;
			grid-template-columns: 60% 1fr;
		}

		.main-container .remark-box .content textarea {
			background: #f5f5f5;
			border: 1px solid #d6d6d6;
			border-radius: 0.5rem;
			padding: 0.625rem;
			outline: none;
		}

		.main-container .save-box {
			margin-top: 1.25rem;
			width: 100%;
			display: grid;
			grid-template-columns: 60% 1fr;
		}

		.main-container .save-box .save-btn {
			background: #4284DC;
			color: #fff;
			border-radius: 0.375rem;
			padding: 0.9375rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}
	}

	@media screen and (min-width: 768px) and (max-width: 1288px) {
		.container {
			width: 100%;
			padding: 2.5rem 3.5rem;
			background-color: #f5f5f5;
		}

		.list-container {
			background-color: #ffffff;
			width: 100%;
			border-radius: 0.625rem;
		}

		.header-container {
			padding: 2.1875rem 1.5625rem;
		}
	}

	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px) {
		.display-none {
			display: none !important;
		}

		.mb-display-block {
			display: block;
		}

		.container {
			width: 100%;
		}

		.list-container {
			background-color: #ffffff;
			width: 100%;
			border-radius: 0.625rem;
		}

		.header-container {
			padding: 0.75rem 0rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid #E9E9E9;
			background-color: #4284DC;
			position: relative;
		}

		.header-container .title {
			color: #ffffff;
			display: flex;
			align-items: center;
			column-gap: 0.375rem;
			font-size: 1.25rem;
			justify-content: center;
			flex: 1;
		}

		.header-container .action {
			display: flex;
			align-items: center;
			justify-content: center;
			column-gap: 0.9375rem;

		}

		.header-container .mb-return {
			color: #fff;
			position: absolute;
			left: 0;
			padding-left: 0.9375rem;
			display: flex;
			align-items: center;
			column-gap: 0.25rem;
		}

		..header-container .mb-return svg {
			transform: scale(0.6);
		}

		.header-container .action .sub-btn {
			position: fixed;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			bottom: 0;
			left: 0;
			padding: 1.25rem;
			background: #fff;
			box-sizing: border-box;
			box-shadow: -1px 0px 5px rgba(0, 0, 0, 0.2);
			cursor: pointer;
			z-index: 9;
		}

		.header-container .action .sub-btn div {
			width: 100%;
			background: #4284DC;
			color: #fff;
			border-radius: 0.375rem;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.625rem 0;
			font-size: 0.9375rem;
		}

		.header-container .action .return-btn {
			font-size: 1.125rem;
			color: #4284DC;
			border: 1px solid #4284DC;
			padding: 0.875rem 2rem;
			border-radius: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
		}

		.header-container .action .return-btn span {
			margin-left: 0.3125rem;
		}

		.main-container {
			width: 100%;
			padding: 0.9375rem;
		}

		.main-container .pack-list-box {
			width: 100%;
		}

		.main-container .pack-list-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			color: #4284DC;
		}

		.main-container .pack-list-box .content {
			display: grid;
			grid-template-columns: 1fr;
			grid-column-gap: 4.375rem;
			grid-row-gap: 0.625rem;
			font-size: 0.9075rem;
		}

		.main-container .pack-list-box .content .item {
			display: flex;
			align-items: center;
		}

		.main-container .pack-list-box .content .item img {
			margin-right: 0.625rem;
			width: 1rem;
			aspect-ratio: 1/1;
		}

		.main-container .address-box {
			width: 100%;
		}

		.main-container .address-box .title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			margin-top: 1.25rem;
		}

		.main-container .address-box .content {
			display: grid;
			grid-template-columns: 1fr;
		}

		.main-container .address-box .content .address {
			width: 100%;
			background-color: #f5f5f5;
			border-radius: 0.5rem;
			border: 1px solid #d6d6d6;
			padding: 0.9375rem;
			display: grid;
			grid-template-columns: 1fr auto;
		}

		.main-container .address-box .content .address .left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}

		.main-container .address-box .content .address .left .__1 {
			display: flex;
			flex-direction: column;
		}

		.main-container .address-box .content .address .left .__1 .phone {
			margin-top: 0.375rem;
		}

		.main-container .address-box .content .address .right {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			color: #4284DC;
			row-gap: 0.375rem;
			font-size: 0.9375rem;
		}

		.main-container .address-box .content .address .right .qiehuan-box {
			width: 1.25rem;
			aspect-ratio: 1/1;
		}

		.main-container .router-select-box {
			width: 100%;
		}

		.main-container .router-select-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			margin-top: 1.25rem;
		}

		.main-container .router-select-box .content {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr;
		}

		.main-container .router-select-box .content .select-box {
			width: 100%;
			background: #f5f5f5;
			border: 1px solid #d6d6d6;
			border-radius: 0.5rem;
			position: relative;
		}

		.main-container .router-select-box .content .select-box .select-input {
			cursor: pointer;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1.125rem 0.9375rem;
		}

		.main-container .router-select-box .content .select-box .img-box {
			width: 1rem;
			aspect-ratio: 1/1;
		}

		.main-container .router-select-box .content .select-box .select-list {
			position: absolute;
			top: 110%;
			background: #f5f5f5;
			border: 1px solid #d6d6d6;
			min-height: 0.625rem;
			max-height: 13.5rem;
			width: 100%;
			z-index: 22;
			left: 0;
			border-radius: 0.375rem;
			overflow: hidden;
			overflow-y: scroll;
		}

		.main-container .router-select-box .content .select-box .select-list .select-item {
			padding: 0.9375rem;
			font-size: 0.9375rem;
			border-bottom: 1px solid #E9E9E9;
		}

		.main-container .router-select-box .content .select-box .select-list .select-item:last-child {
			border-bottom: none;
		}

		.main-container .remark-box {
			width: 100%;
		}

		.main-container .remark-box .title {
			font-size: 1.125rem;
			margin-bottom: 0.9375rem;
			margin-top: 1.25rem;
		}

		.main-container .remark-box .content {
			width: 100%;
			display: grid;
			grid-template-columns: 1fr;
		}

		.main-container .remark-box .content textarea {
			background: #f5f5f5;
			border: 1px solid #d6d6d6;
			border-radius: 0.5rem;
			padding: 0.625rem;
			outline: none;
		}
	}
</style>
<style>
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	/* @media screen and (max-width: 768px) {
	.page-header-container {
		display: none !important;
	}
	.page-footer-container {
		display: none !important;
	}
} */
</style>